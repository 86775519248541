<template>
    <div class="page-content">
        <md-app md-waterfall md-mode="fixed">
            <md-app-drawer md-permanent="full">
                <md-toolbar class="md-transparent" md-elevation="0">
                    <span style="font-weight:500; font-size:16px;">Loại phương tiện</span>
                </md-toolbar>
                <md-list>
                    <md-list-item v-for="(item, index) in categories" :key="'g_' + item.id" md-expand :md-expanded="expandAll">
                        <md-checkbox @change="selectAll(index)" v-model="item.selected"/>
                        <span class="md-list-item-text">{{item.groupName}}</span>

                        <md-list slot="md-expand" style="padding-left: 20px;">
                            <md-list-item v-for="i in item.children" :key="'c_' + i.id" >
                                <md-checkbox @change="selectChild(index)" v-model="i.selected"/>
                                <span class="md-list-item-text">{{i.groupName}}</span>
                            </md-list-item>
                        </md-list>
                    </md-list-item>
                </md-list>
            </md-app-drawer>

            <md-app-content style="padding: 0;">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách phương tiện/xe nâng</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action">
                                <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                                <md-button v-shortkey="['ctrl', 'e']" @click="exportExcel()" class="md-raised md-primary btn-add">
                                    Export<md-tooltip>Xuất excel</md-tooltip>
                                </md-button>
                                <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                                    <md-icon>cached</md-icon>
                                    <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="page-list-search">
                        <div class="row">
                            <div class="col l-4 m-4 c-12">
                                <md-field>
                                    <label>Tìm theo biển số</label>
                                    <md-input v-model="search.licensePlate"></md-input>
                                </md-field>
                            </div>
                            <div class="col l-4 m-4 c-12">
                                <md-radio v-model="isPPL" :value="0">Tất cả</md-radio>
                                <md-radio v-model="isPPL" :value="1">PPL</md-radio>
                                <md-radio v-model="isPPL" :value="2">Cty ngoài</md-radio>
                            </div>
                            <div v-show="isPPL == 2"  class="col l-3 m-3 c-12">
                                <div class="form-control">
                                    <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies">
                                        <label>Tất cả Cty vận tải ngoài</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.companyName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                    </md-autocomplete>
                                    <md-button @click="openCompany()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                        <md-tooltip>Tìm công ty vận tải ngoài</md-tooltip>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="table-content">
                            <div class="list" style="overflow-x: auto; width: 100%;">
                                <table class="data-table" style="width: 2150px;">
                                    <thead>
                                        <tr>
                                            <th style="width:100px;">#</th>
                                            <th style="width:100px;">Hành động</th>
                                            <th style="width:120px;">Biển số</th>
                                            <th style="width:150px;">Mã Ph.tiện</th>
                                            <th style="width:150px;">Chi nhánh</th>
                                            <th style="width:120px;">Nhãn hiệu</th>
                                            <th style="width:150px;">Chủ Ph.tiện</th>
                                            <th style="width:100px;">Số trục</th>
                                            <th style="width:100px;">Số khung</th>
                                            <th style="width:100px;">Số máy</th>
                                            <th style="width:100px;">Năm SX</th>
                                            <th style="width:100px;">Niên hạn</th>
                                            <th style="width:100px;">Kích thước</th>
                                            <th style="width:120px;">KL bản thân</th>
                                            <th style="width:120px;">KL tổng chở</th>
                                            <th style="width:120px;">Thông số lốp</th>
                                            <th style="width:150px;">Công suất động cơ</th>
                                            <th style="width:120px;">Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="loadding == true" style="height: 150px;">
                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    </tbody>
                                    <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                        <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                    </tbody>
                                    <tbody v-if="loadding == false && pager.totalItems > 0">
                                        <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                            <td class="center">{{index + 1}}</td>
                                            <td class="action">
                                                <router-link :to="'/forklift/crud/' + item.id">
                                                    <md-button class="md-fab md-mini md-second">
                                                        <md-icon>edit</md-icon>
                                                        <md-tooltip>Sửa</md-tooltip>
                                                    </md-button>
                                                </router-link>
                                                <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                                    <md-icon>clear</md-icon>
                                                    <md-tooltip>Xóa</md-tooltip>
                                                </md-button>
                                            </td>
                                            <td class="center">{{item.licensePlate}}</td>
                                            <td class="center">{{item.vehicleCode}}</td>
                                            <td class="center">
                                                <span v-if="item.branchId > 0">{{item.branch.branchName}}</span>
                                            </td>
                                            <td class="left">{{item.brand.brandName}}</td>
                                            <td class="left">{{item.owner.companyAlias}}</td>
                                            <td class="center">{{item.shaftNumber}}</td>
                                            <td class="left">{{item.chassisNo}}</td>
                                            <td class="left">{{item.engineNo}}</td>
                                            <td class="center">{{item.manufacturedYear}}</td>
                                            <td class="center">{{item.expireYear}}</td>
                                            <td class="center">{{item.dimension}}</td>
                                            <td class="center">{{item.kerbMass}}</td>
                                            <td class="center">{{item.payload}}</td>
                                            <td class="left">{{item.tire}}</td>
                                            <td class="left">{{item.maxOutput}}</td>
                                            <td class="center">
                                                <span v-if="item.status == 1" class="s-active">Hoạt động</span>
                                                <span v-else-if="item.status == 2" class="s-active">Sửa chữa</span>
                                                <span v-else class="s-inactive">Tạm dừng</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="table-footer" v-if="pager.totalPages > 1">
                            <div class="grid">
                                <div class="row">
                                    <div class="col l-3 m-3 c-12">
                                        <div class="move-page">
                                            <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                            <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                        </div>
                                    </div>
                                    <div class="col l-6 m-6 c-12">
                                        <div class="paging">
                                            <div class="total-page">
                                                <div>{{paging.page}}</div> 
                                                <div style="padding-left:10px;">
                                                    <md-field>
                                                        <md-select v-model="search.pageIndex">
                                                            <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                        </md-select>
                                                    </md-field>
                                                </div>
                                                <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                            </div>
                                            <div class="row-of-page">
                                                <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                                <md-field>
                                                    <md-select v-model="search.pageSize">
                                                        <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                                    </md-select>
                                                </md-field>
                                            </div>
                                            <div class="record-of-page">
                                                <div style="padding-right:10px;">{{paging.view}}:</div>
                                                <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col l-3 m-3 c-12">
                                        <div class="move-page">
                                            <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                            <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-app-content>
        </md-app>
        <companyList ref="companyList" title="vận tải ngoài" :type="transportType" @close="closeCompany"/>
    </div>
</template>
<script>
    import vehicleGroupService from '../../../api/vehicleGroupService';
    import companyService from '../../../api/companyService';
    import vehicleService from '../../../api/vehicleService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import companyList from '../../../components/popup/_CompanyList.vue';
    import { APP_CONFIG } from '@/utils/constants'

    export default {
        components: {
            companyList
        },
        metaInfo: {
            title: 'Danh sách phương tiện'
        },
        data() {
            return {
                loadding: false,
                isPPL: 0,
                search: { pageIndex: 1, pageSize: common.pageSize, isVehicle: false, groups: '', licensePlate: '', ownerId: 0 },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                categories: [],
                expandAll: true,
                selectOne: false,
                transportType: common.companyType.transport,
                companyName: '',
                companies: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.getCategories();
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/forklift/export-vehicle?groups=" + 
                this.search.groups + "&licensePlate=" + this.search.licensePlate + "&ownerId=" + this.search.ownerId;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            //OutSource
            closeCompany(item){
                this.companyName = item.companyName;
                this.search.ownerId = item.id;
                this.$refs.companyList.close();
            },

            getCompanySelected(val){
                this.search.ownerId = val.id;
                this.companyName = val.companyName;
            },

            openCompany(){
                this.$refs.companyList.open();
            },

            getCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.transportType, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            selectChild(parentIndex){
                let selectAll = false;
                for(let i = 0; i < this.categories[parentIndex].children.length; i++){
                    if(this.categories[parentIndex].children[i].selected){
                        selectAll = true;
                    }
                    else{
                        this.categories[parentIndex].selected = false;
                        selectAll = false;
                        break;
                    }
                }
                if(selectAll){
                    this.categories[parentIndex].selected = true;
                }

                this.getSelected();
            },

            selectAll(parentIndex){
                for(let j = 0; j < this.categories[parentIndex].children.length; j++){
                    if(this.categories[parentIndex].selected){
                        this.categories[parentIndex].children[j].selected = true;
                    }
                    else{
                        this.categories[parentIndex].children[j].selected = false;
                    }
                }
                this.getSelected();
            },

            getSelected(){
                let groups = [];
                this.selectOne = false;
                for(let i = 0; i < this.categories.length; i++){
                    for(let j = 0; j < this.categories[i].children.length; j++){
                        if(this.categories[i].children[j].selected){
                            let exist = -1;
                            if(groups.length > 0){
                                exist = groups.indexOf(this.categories[i].children[j].id);
                            }
                            if(exist === -1){
                                groups.push(this.categories[i].children[j].id);
                            }
                        }
                    }
                }
                for(let h = 0; h < this.categories.length; h++){
                    if(this.categories[h].selected){
                        this.selectOne = true;
                    }
                    for(let k = 0; k < this.categories[h].children.length; k++){
                        if(this.categories[h].children[k].selected){
                            this.selectOne = true;
                        }
                    }
                }
                if(groups.length === 0 && this.selectOne === true){
                    this.search.groups = String('0');
                }
                else{
                    this.search.groups = String(groups);
                }
                
                this.getData();
            },

            getCategories(){
                this.loadding = true;
                vehicleGroupService.getAll(false).then((response) => {
                    let children = [];
                    if(response.statusCode == 200){
                        for(let i = 0; i < response.data.length; i++){
                            children = [];
                            for(let j = 0; j < response.data[i].children.length; j++){
                                children.push({ selected: false, id: response.data[i].children[j].id, groupName: response.data[i].children[j].groupName });
                            }
                            this.categories.push({ selected: false, children: children, id: response.data[i].id, groupName: response.data[i].groupName });
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, groups: '', licensePlate: '' };
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/forklift?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                this.search.isVehicle = false;
                vehicleService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                vehicleService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$router.push('/forklift/crud');
            }        
        },
        watch: {
            companyName: function (val) { 
                if(val == ''){
                    this.search.ownerId = 0;
                }
            },
            isPPL: function (val) { 
                if(val == 0){
                    this.search.ownerId = 0;
                }
                else if(val == 1){
                    this.search.ownerId = 1;
                }
                else if(val == 2){
                    this.companyName = '';
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.licensePlate': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.ownerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }

</script>

<style scoped>
    .md-drawer {
        width: 330px;
        max-width: calc(100vw - 165px);
    }
    .md-checkbox {
        margin-right: 10px !important;
    }
</style>
